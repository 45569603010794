/*
 * @Author: your name
 * @Date: 2021-04-30 13:33:07
 * @LastEditTime: 2021-08-26 19:22:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hello\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

    zh: window.localStorage.getItem("zh"),
    en: window.localStorage.getItem("en"),
    aaa:1111,
    account: '',
    chainId: '',
    balance: 0,
    receive: 0,
    receiveDisabled: true
  },
  getters: {
    gettersColor: state => {

      const gettersColor = state.initColor;
      return gettersColor
    }
  },
  mutations: {
    updateInitColor (state, payload) {
      state.initColor = payload
    },
    setAccount(state, value) {
      state.account = value
    },
    setChainId(state, value) {
      state.chainId = value
    },
    setBalance(state, value) {
      state.balance = value
    },
    setReceive(state, value) {
      state.receive = value
    },
    setReceiveDisabled(state, value) {
      state.receiveDisabled = value
    }
  },
  actions: {
    // 708090
  },
  modules: {
  }
})
