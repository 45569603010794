/*
 * @Author: your name
 * @Date: 2021-04-30 13:33:07
 * @LastEditTime: 2021-08-24 14:42:28
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \hello\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

const home =()=> import('../views/home/home.vue')

Vue.use(VueRouter)
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [
    {
    path:'/',
    redirect:'/home'
   }, 
  {
    path:'/home',
    component:home, 
    meta:['首页'],
   
  },
 

]

// router.beforeEach((to,from, next)=>{

//   if(to.path === '/login'){
//     next()
//   }else{
//       const token = sessionStorage.getItem('token')
//       if(!token){
//         next('/login')
//       }else{
//         next()
//       }
//   }
//    
// })
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
