<template>
  <div id="app">
    <router-view />
</div>
</template>

<script>

  export default{
    components:{
   
    }
  }
</script>
<style>
   @import './assets/css/public.css'


</style>
