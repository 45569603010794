/*
 * @Author: your name
 * @Date: 2021-04-30 13:33:07
 * @LastEditTime: 2021-08-31 14:15:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \练习\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import 'lib-flexible'
import router from './router'
import store from './store'
import axios from 'axios';
import less from 'less'
Vue.use(less)
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
Vue.prototype.$axios = axios;
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
const i18n = new VueI18n({ 

   locale: 'en',  

   messages: {   

      'zh': require('@/assets/languages/zh.json'),   

      'en': require('@/assets/languages/en.json') 

    },

  });
  let zhyuyan = require('@/assets/languages/zh.json')
  let enyuyan = require('@/assets/languages/en.json')
  // console.log(i18n.messages.zh, 25555)
  window.localStorage.setItem("zh",zhyuyan);
  window.localStorage.setItem("en", enyuyan);



// import echarts from 'echarts'

// Vue.prototype.$echarts = echarts
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  axios,
  render: function (h) { return h(App) }
}).$mount('#app')
